import React, { useState } from "react"
import { slice } from 'lodash';
import ShareImage from '../../images/icons/share.png'
import LikeImage from '../../images/icons/like.png'
import QuoteImage from '../../images/icons/quote.png'
import SocialShare from '../common/SocialButtons';
import LikeIconActive from '../../images/icons/like-active.png';
import "./wideImage.scss"
import { Link } from "gatsby"
import { api } from '../../../utils';

const WideImageCard = (props) => {
    const [openCls, setOpenCls] = useState(false)
    const { page, location, shReadDurationMap, user, bookmarks, fetchBookmarks } = props;

    const description = page.acf_super_honestly.description;
    const words = description.split(" ");
    const wordData = slice(words, 0, 20);

    const bookmarkTip = (slug) => {
        const fData = {
            type: 'super-honestly',
            slug
        }
        api.post('/api/v1/user-bookmark/toggle', fData)
        .then(resp => {
            if (resp && resp.success) {
                fetchBookmarks();
            } else {
                alert("Something went wrong. Please try again later!");
            }
        });
    }
 
    return (
        <div className="wideImage-texture w3-col s12 m12">
            <Link to={`/super-honestly/${page.slug}`} className="w3-round-xlarge wideImageMainCard" style={{ textDecoration: 'none' }}>
                <img loading="lazy" src={`${page.acf_super_honestly.thumbnail.sourceUrl}`} className="wideImageRound wideImage" />
                <div className="wideImageContent-main">
                    <div className="wideImageContent-sub">
                        <img loading="lazy" src={QuoteImage} className="wideImageQuoteImage" />
                        <div className="wideImagePaddingLeft">
                            <div className="wideImageContent">{page.title}</div><br />
                            <div className="wideImage-desc">{description ? `${wordData.join(' ')}...` : ''}</div>
                        </div>
                    </div> 
                    <div className="wideImageIcons">
                        <div className="wideImageIcon-sub">
                            <div className="wideImage-duration">{`${(shReadDurationMap && shReadDurationMap[page.slug]) ? shReadDurationMap[page.slug] : 'x'} min read`}</div>
                        </div>
                        <div className={openCls ? 'wi-ss-icons' : ''} style={{display: "flex"}}>
                            <div className="" style={{marginRight: "15px"}}> 
                                {(user) && (
                                    <button style={{ background: 'none', padding: '0px', border: 'none', outline: 'none', cursor: 'pointer' }}
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            evt.stopPropagation();
                                            bookmarkTip(page.slug);
                                        }}
                                    > 
                                        <img className="like-img" src={(bookmarks.indexOf(page.slug) !== -1) ? LikeIconActive : LikeImage} />
                                    </button>
                                )}
                            </div>
                            <div className={`share-img ${openCls == false ? "w3-show" : "w3-hide"}`}> 
                                <img src={ShareImage} onClick={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    setOpenCls(true)
                                }} />
                            </div>
                            <SocialShare data={openCls} handleSetOpenCls={(someData) => {setOpenCls(someData)}} 
                                title={page.title} link={`${location.origin}/super-honestly/${page.slug}`}
                                subjectTitle={'Check out this story!'} description={description}
                            />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}   

export default WideImageCard
