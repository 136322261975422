import React, { useState } from "react"
import StoryImageCard from "../StoryImageCard";
import UpIcon from '../../images/icons/up-icon.png'
import { slice } from 'lodash';
import "./recent-stories.scss";

const RecentStories = (props) => {
  const { renderData, location, shReadDurationMap, user, bookmarks, fetchBookmarks, count, tags } = props;
   const[selected,setSelected] = useState(""); 
  return <>
    <div className="w3-row view">
      {renderData.slice(0, count).map((cardData,idx) => 
          <StoryImageCard key={`${cardData.title}-${idx}`} 
              data={cardData} 
              share = {idx === selected }
              idx = {idx}
              setSelected = {(idx) => setSelected(idx)}
              location={location} 
              shReadDurationMap={shReadDurationMap} 
              user={user} 
              bookmarks={bookmarks} 
              fetchBookmarks={fetchBookmarks}
              tags={tags}
          /> 
      )}
      <div onClick={() => window.scroll({ top: 120, behavior: "smooth" })} className="w3-hide-medium w3-hide-large up-arrow-story">
        <img src={UpIcon} />
      </div>
    </div>
  </>
}

export default RecentStories;