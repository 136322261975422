import React, {useState} from "react"
import ShareImage from '../../images/icons/share.png'
import LikeImage from '../../images/icons/like.png'
import QuoteImage from '../../images/icons/quote.png'
import LikeIconActive from '../../images/icons/like-active.png';
import "./storyImage.scss"
import { Link } from "gatsby"
import { slice } from 'lodash';
import SocialShare from '../common/SocialButtons';
import { api } from '../../../utils';

const StoryImageCard = (props) => {
    const { data, type, location, shReadDurationMap, user, bookmarks, fetchBookmarks, tags, setSelected, share, idx  } = props;
    if (!data || data.status !== 'publish') {
        return null;
    }

    const bookmarkTip = (slug) => {
        const fData = {
            type: 'super-honestly',
            slug
        }
        api.post('/api/v1/user-bookmark/toggle', fData)
        .then(resp => {
            if (resp && resp.success) {
                fetchBookmarks();
            } else {
                alert("Something went wrong. Please try again later!");
            }
        });
    }

    const str = data.title;
    const title = slice(str, 0, 85);
  
    return (
        <div className={`storyImage-texture w3-col s12 ${type == 'trending' ? 'm12' : tags ? 'm6 l3' : 'm6 l4'}`}>
            <div className={`storyImageBorder storyImageMargin ${type == 'trending' ? 'background-trend' : 'background'}`}>
                <Link className="link" to={`/super-honestly/${data.slug}`}>
                    <img loading="lazy" src={`${data.acf_super_honestly.thumbnail.sourceUrl}`} className="cardImage" />
                    <div className="w3-padding storyImage-content-main">
                        <img loading="lazy" src={QuoteImage} className="storyImage-quote" />
                        <div className="w3-margin-left storyImage-content">{title.length >= 85 ? `${title.join('')}...` : data.title}</div>
                    </div>
                    <div className="w3-row footer">
                        <div className='w3-col s7' style={{marginTop: "8px", marginBottom: 8}}>
                            <div className="storyImage-duration">{`${(shReadDurationMap && shReadDurationMap[data.slug]) ? shReadDurationMap[data.slug] : 'x'} min read`}</div>
                        </div>
                        <div className='w3-col s4 storyImage-icons'>
                            <div className={`storyImage-icons-sub`}> 
                                {(user) && (
                                    <button style={{ background: 'none', padding: '0px', border: 'none', outline: 'none', cursor: 'pointer' }}
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            evt.stopPropagation();
                                            bookmarkTip(data.slug);
                                        }}
                                    > 
                                        <img className="like-img" src={(bookmarks.indexOf(data.slug) !== -1) ? LikeIconActive : LikeImage} />
                                    </button>
                                )}
                            </div>
                            <div className={`share-img ${share == false ? "w3-show" : "w3-hide"}`}> 
                                <img src={ShareImage} onClick={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    setSelected(idx)
                                }} />
                            </div>
                            <SocialShare data={share} handleSetOpenCls={(someData) => {setSelected(someData)}} 
                                title={data.title} link={`${location.origin}/super-honestly/${data.slug}`} 
                                subjectTitle={'Check out this story!'} description={data.acf_super_honestly.description}
                            /> 
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default StoryImageCard;