import React, { useState, useEffect } from "react"
import querystring from 'querystring';
import Layout from "../../components/layout"
import WideImageCard from "../../components/WideImageCard"
import "../../pages/pages.scss"
import SmQuotesGroupIcon from '../../images/icons/logo-mo.png';
import BackArrow from "../../images/icons/arrow-left.png"
import RecentStories from '../../components/RecentStories';
import SEO from "../../components/seo";

const Logo = "https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png";
  
const SuperHonestlyTags = ({ location, pageContext: { superHonestlyList, superHonestlyPage, shReadDurationMap } }) => {

    const shPage = superHonestlyPage.acf_super_honestly_list;
    const masterData = superHonestlyList;

    const LIMIT = 8;
    const [renderData, setRenderData] = useState(masterData);
    const [shVisibleCount, setSHVisibleCount] = useState(LIMIT);
    const [topCard, setTopCard] = useState(null)
    const [heading, setHeading] = useState(null);

    useEffect(() => {
      setQueryFilters(location.search.replace('?', ''));
    }, []);

    const setQueryFilters =  (data) => {
      const queryStr = querystring.parse(data.replace('?', ''));

      let filterList = [];
      masterData.forEach(item => {
        item.tags.nodes.forEach(node => {
            if (node.slug === queryStr.tags) {
                filterList.push(item);
                setHeading(node.name)
            }
        });
      });
      shPage.topCard.map(item => {
        item.tags.nodes.forEach(node => {
            if (node.slug === queryStr.tags) {
                setTopCard(item);
            }
        });
      });
      if (filterList.length) {
          setRenderData(filterList);
      } else {
          setRenderData(masterData);
      }
    }

    const loadMore = () => {
        setSHVisibleCount(prevState => (prevState + LIMIT));
    }

    return (
      <div className="super-ho-background">
        <div className="w3-hide-large w3-hide-medium" style={{position: "relative"}}>
          <img loading="lazy" className="about-us-os-logo" src={SmQuotesGroupIcon} />
        </div>
        <Layout>
            <SEO
              title="SuperHonestly"
              path={location.pathname}
            />
            <img loading="lazy" className="super-ho-logo w3-hide-small" src={Logo} />
            <div className="w3-row" style={{ position: "relative" }} onClick={() => {window.history.back()}}>
                <div className="pw-wt-detail-back-to" style={{ position: "absolute" }}>
                    <img src={BackArrow} className="pw-wt-detail-back-icon" />
                    <div className="">
                        Back to Story
                    </div>
                </div>
            </div>
            <div className="w3-hide-large w3-hide-medium" style={{display: "flex", marginTop: "25px"}}>
                <div className="super-ho-title">{heading ? heading.toUpperCase() : ''}</div>
            </div>
            <div className="about-us-view" style={{paddingBottom: "20px"}}>
                <div className="super-ho-title w3-hide-small">{heading ? heading.toUpperCase() : ''}</div>
                <div className="pw-super-ho-top-card-container">
                  <div className="w3-content">
                    <div className="topCard">
                        {topCard != null  || location.search == "" ?
                            shPage.topCard.map((story) => <WideImageCard page={story} location={location} shReadDurationMap={shReadDurationMap} /> )
                        : null}
                    </div>
                  </div>
                </div>
                <div className="w3-row">
                    <div className="w3-content">
                        <div className="w3-col m12 l12">
                            <div className="">
                              <RecentStories renderData={renderData} location={location} shReadDurationMap={shReadDurationMap} count={shVisibleCount} tags={true} />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="w3-row load">
                  {shVisibleCount > renderData.length ?
                    <div className="w3-padding-16"></div>
                    : <div  className="recent-stories" onClick={loadMore}>
                          LOAD MORE
                      </div>
                    }
                </div>
            </div>
          </Layout>
      </div>
    )
}

export default SuperHonestlyTags;